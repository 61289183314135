@import './variables.scss';

.ws-why-buy {
	.why-buy-items {
		.why-buy-item {
			.ddc-icon {
				line-height: 1;
				font-size: $why-buy-item-icon-font-size;
			}

			.description {
				line-height: 1.25;
			}
		}
	}
}
